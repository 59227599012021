export default {
  blogerName: 'MENDIGO',
  socialsList: [
    {
      name: 'x',
      url: 'https://twitter.com/OmendigoTV',
    },
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/omendigotv',
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/omendigotv/',
    },
    {
      name: 'website',
      url: 'https://mendigotv.com/',
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://monrocasino.life/c472ae824',
      gameTitle: 'Savage Buffalo Spirit Megaways (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/cb14ff8cb',
      gameTitle: 'Royal High-Road (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c7267dfd3',
      gameTitle: 'LEGZO PUNK (BGaming)',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c9b8d5503',
      gameTitle: 'Starda Queen (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>MENDIGO</strong> e receba 100 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'MENDIGO',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>100FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark'
}
